import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogDataExampleDialog } from '../popup.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { MzModalComponent } from 'ngx-materialize';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.less']
})
export class HistoryComponent implements OnInit {
  @ViewChild('bottomSheetModal') bottomSheetModal: MzModalComponent
  userInfo: any;
  masterData: any;
    ApprovalId: number;
    ApprovalType: number;
  DisagreementData: any;
  DisagreementRemarks: string;
  ChangeMatrixRemarks: string;
  displayedColumns: string[] = [
    'SalesMetricName',
    'Weightage',
    'Target',
    'Threshold',
    'Actual',
    'AchievementValue',
    'Comments',
    'ApproverComments'
  ];
  Type: string;
  ShowData: boolean = false;
  AppType: number;
  //dataSource = ELEMENT_DATA;
  constructor(private appService: AppService, private configs: Configs, private _router: Router, public dialog: MatDialog) {
    this.DisagreementData = {};
    this.Type = '0';
    this.DisagreementRemarks = '';
    this.ChangeMatrixRemarks = '';
  }

  ngOnInit() {
    this.userInfo = this.appService.GetLoginUserInfo();
    this.masterData = this.appService.GetMasterDetails();
      this.ApprovalId = this.appService.GetApprovalID();
    this.ApprovalType = this.appService.GetApprovalType();
    this.Type = this.appService.GetCorporateUserType();
    this.AppType = this.userInfo.AppType;
    if (this.ApprovalId != 0) {
      this.GetData();
    }
    else {
      this._router.navigate(['no-access']);
    }

  }
  onBackClick(): void {
    this.appService.SetFromHistory('Y');
    this._router.navigate(['reject-assessment']);
  }

  GetData() {
    var data = {
      "ApprovalId": this.ApprovalId,
      "ActionType": 0
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetDisagreementDataURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.DisagreementData = response.ResponseData;
          if (this.DisagreementData.dtMertricsDet.length>0) {
            this.ShowData = true;
          }
          for (var o in this.DisagreementData.MetricsDisagreementDataList) {
            this.DisagreementData.MetricsDisagreementDataList[o].ApproverComments = '';
          }
        }
      },
        error => {
          // this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
        });
  }
  RevertActualsSubmit() {
    if (this.DisagreementRemarks.trim() == '' || this.DisagreementRemarks.trim() == undefined) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please enter Comments"
      });
      return;
    }


    var PendingWith = 0;

    var Data = {
      "PS_No": this.userInfo.PS_No,
      "ApprovalId": this.ApprovalId ,
      "Role": 'Approver',
      "Remarks": this.DisagreementRemarks,
      "ReasonsSelected": this.DisagreementData.ReasonsSelected,
      "PendingWith": this.Type,
      "MetricsDisagreementData": this.DisagreementData.MetricsDisagreementDataList,
      "AppType": this.userInfo.AppType
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.ApproveDisagreementURL();
    this.appService.GetDataFromAPI(url, Data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.onBackClick();
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
          this.DisagreementRemarks = '';

        }
      },
        error => {
          // this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
        });
  }

  ChnageApproverMatrix() {

    this.ChangeMatrixRemarks = this.DisagreementRemarks;

    if (this.ChangeMatrixRemarks.trim() == '' || this.ChangeMatrixRemarks.trim() == undefined) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please enter Comments"
      });
      return;
    }


    var PendingWith = 0;

    var Data = {
      "PSNo": this.userInfo.PS_No,
      "ApprovalId": this.ApprovalId,
      "Role": 'Approver',
      "Remarks": this.ChangeMatrixRemarks,
      "ReasonsSelected": this.DisagreementData.ReasonsSelected,
      "PendingWith": this.Type,
      "MetricsDisagreementData": this.DisagreementData.MetricsDisagreementDataList,
      "AppType": this.userInfo.AppType
      
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.ChangeWorkFlowforDisagreementURL();
    this.appService.GetDataFromAPI(url, Data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.onBackClick();
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
          this.onBackClick();
          this.DisagreementRemarks = '';

        }
      },
        error => {
          // this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
        });
  }
  openChangeMatrixModal() {
    this.bottomSheetModal.openModal();
  }
  onViewAction(SalesMetricID, PSNo, PeriodID) {

    var ActualDataList = [];
    for (var o in this.DisagreementData.MetricsDisagreementDataList) {
      //if (this.tblMetrics[o].SalesMetricID != 2) {
      var metric = {
        'MetricName': this.DisagreementData.MetricsDisagreementDataList[o].SalesMetricName,
        'MetricId': this.DisagreementData.MetricsDisagreementDataList[o].SalesMetricId,
        'Accounts': []
      }
      ActualDataList.push(metric);
      //}
    }

    var data = {
      'ActualDataList': ActualDataList,
      'SalesMetricID': SalesMetricID,
      'PSNo': PSNo,
      'PeriodID': PeriodID,
      'Path': 'history',

    };
    this.appService.SetActualData(data);
    this._router.navigate(['actual-details']);
  }
  getTeamName() {
    return this.appService.TeamName(this.userInfo.AppType);
  }
}
