import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataExampleDialog } from '../popup.component';
import { forEach } from '@angular/router/src/utils/collection';
import { MzModalComponent } from 'ngx-materialize';
import { MatTabChangeEvent } from '@angular/material';
@Component({
  selector: 'calculations',
  templateUrl: './calculations.component.html',
  styleUrls: ['./calculations.component.less']
})
export class CALCULATIONSComponent implements OnInit {
  PeriodId: any;
  ShowIOptionTypeSection: number;
  filters: any;
  selectedperiodId: any;
  selectedAppType: any;
  selectedCalcFor: any;
  SBUDistinctModel: any;
  SearchText: any;
  selectedSBU: any;
  selectedplan: any;
  selectedPSNo: any = [];
  selectedPSNOForCalc: any = [];
  selectedPSNOForApprovebyCNB: any = [];
  selectedPSNOForApprovebyCP: any = [];
  selectedPSNOForApprovedbyCNBProd: any = [];
  selectedPSNOForApprovedbySEProd: any = [];
  dtHunterData_Content: any;
  dtHunterData_Header: any;
  MappingType: string = '';
  RoleFlag: string = '';
  typeSelected: any;
  ShowEmployeeMapping: boolean = false;
  Reportnumber: any;
  YearType: string = '';
  ApprovePsno: string = '';
  Message: any;
  typeOptionSelected: any;
  optionSelected; any;
  UploadFileTypes: any = [];
  dropdownList: any = [];
  UploadOption: any = [];
  dtplanmaster: any = [];
  dtSBUMaster: any = [];
  dtCalculatePSNo: any = [];
  dtCalculated: any = [];
  dtPendingwithCNB: any = [];
  dtPendingwithCNBprod: any = [];
  dtPendingwithCP: any = [];
  dtPendingwithSEprod: any = [];
  SelectedUploadId: any;
  uploadedFile: File;
  UserPsno: any;
  ShowSection: number;
  selectAll: boolean = true;
  userInfo: any;
  masterData: any;
  UploadOptionTypes: any = [];
  SelectCalcForOption: any = [];
  UploadTypes: any = [];
  DownloadFilePathBaseForDetailedReport: any;
  DownloadFilePathForDetailedReport: any;
 // selectedplan: any;
  dropdownListSBU: any = [];
  dropdownListPlan: any = [];
  count: number;
  selectedSBUArray: any = [];
  selectedPlanArray: any = [];
  dropdownSettings: any = {};
  LoggedInUserRoleText: any;

  //#region "Amartya 01-01-2024"

  //Code here
    PSNumber: any;
    isDTCalculated: any;
    isPendingwithCNBprod: any;
    isPendingWithCNB: any;
    isPendingwithSEprod: any;
    isPendingWithCP: any;
    employeeStatus: any;

  //#endregion "Amartya 01-01-2024"

    dtCalculatedOriginal: any = [];
    dtPendingwithCNBOriginal: any = [];
    dtPendingWithCPOriginal: any = [];

  constructor(private appService: AppService, private configs: Configs, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.ShowIOptionTypeSection = 1;
    this.filters = {
      'dtAppTypeMaster': [],
      'dtPeriodMaster': [],
      'dtPlanMaster': [],
      'dtSBUMaster' : []
    };
    this.selectedperiodId = 0;
    /*this.SBUDistinctModel = [];*/
    this.selectedCalcFor = 0;
    this.selectedAppType = 0;
    this.SearchText = '';
    this.selectedPSNOForCalc = [];
    this.selectedPSNOForApprovebyCNB = [];
    this.selectedPSNOForApprovebyCP = [];
    this.selectedPSNOForApprovedbyCNBProd = [];
    this.selectedPSNOForApprovedbySEProd = [];
    this.dtHunterData_Content = [];
    this.uploadedFile = null;
    this.Message = '';
    this.dtHunterData_Header = [];
    this.Reportnumber = '';
    this.typeOptionSelected = 0;
    this.UploadOption = [];
    this.optionSelected = 0;
    this.selectedplan = [];
    this.selectedSBU = [];
    this.count = 0;
    
    /*this.dropdownListSBU = [];*/



    this.masterData = {};
    this.UserPsno = '';
    this.ShowSection = 1;
    this.UploadOptionTypes = [/*{ 'OptionTypeId': 1, 'OptionType': 'HunterUSA' },*/ { 'OptionTypeId': 2, 'OptionType': 'Incentive Calculation' }]
    this.SelectCalcForOption = [{ 'CalcForOptionId': 1, 'CalcForOptionType': 'All' }, { 'CalcForOptionId': 2, 'CalcForOptionType': 'PSno(s)' }]
    this.UploadFileTypes = [{ 'TypeId': 1, 'Type': 'PID Mapping' }, { 'TypeId': 2, 'Type': 'Account Mapping' }]
    this.UploadTypes = [{ 'TypeId': 1, 'Type': 'PID Mapping' }]
    this.DownloadFilePathBaseForDetailedReport = '../../../UploadTemplates/DetailedReport_';
    this.DownloadFilePathForDetailedReport = '';

    //#region INITIALISE to null
    this.PSNumber = '';
    this.isDTCalculated = '';
    this.isPendingWithCNB = '';
    this.isPendingWithCP = '';
    this.isPendingwithCNBprod = '';
    this.isPendingwithSEprod = '';
    this.employeeStatus = '';
    //#endregion
    this.optionSelected = this.UploadOptionTypes[0].OptionTypeId
    this.LoggedInUserRoleText = '';
  }
  ngOnInit() {
    this.appService.SetLoadingShow(false);
    this.userInfo = this.appService.GetLoginUserInfo();
    //if (this.appService.GetSelectedUploadId() != '') {
    //  this.SelectedUploadId = this.appService.GetSelectedUploadId();
    //}
    //if (this.appService.GetperiodId() != '') {
    //  this.selectedperiodId = this.appService.GetperiodId();
    //}
    //if (this.appService.GetTypeSelected() != '') {
    //  this.typeSelected = this.appService.GetTypeSelected();
    //}
    //if (this.appService.GetOptionSelected() != '') {
    //  this.optionSelected = this.appService.GetOptionSelected();
    //}
    //if (this.appService.GetAppTypeSelected() != '') {
    //  this.selectedAppType = this.appService.GetAppTypeSelected();
    //}
    //if (this.appService.GetPlanSelected() != '') {
    //  this.selectedplan = this.appService.GetPlanSelected();
    //}
    //if (this.appService.GetSBUSelected() != '') {
    //  this.selectedSBU = this.appService.GetSBUSelected();
    //}
    if (this.userInfo != null) {

      var checkAccess = this.appService.CheckAccessForRoute(this.router.url, this.userInfo.UserAccessOptions);
      if (checkAccess) {
        this.UserPsno = this.userInfo.PS_No;
        this.dropdownSettings = {
          singleSelection: false,
          text: "Select",
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          enableSearchFilter: false,
          badgeShowLimit: 1,
          classes: ""
        };
        this.masterData = this.appService.GetMasterDetails();
        this.getMasterTable();
        this.ShowSection = 0;
        this.ShowIOptionTypeSection = 0;
        this.LoadUploadsForUser();
      }
      else {
        this.router.navigate(['no-access']);
      }
    }
    else {
      window.location.href = "../Index.html";
    }
  }

  getMasterTable(): void {    
    var data = {      
      "AppType": this.userInfo.AppType
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetCalculaionMasterTableURl();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.filters.dtPeriodMaster = response.ResponseData.dtPeriodMaster;
          this.filters.dtAppTypeMaster = response.ResponseData.dtAppTypeMaster;
          this.filters.dtPlanMaster = response.ResponseData.dtPlanMaster;
          this.filters.dtSBUMaster = response.ResponseData.dtSBUMaster;
          /*this.filters.dtUploadsMaster = response.ResponseData.dtUploadsMaster;*/
        }
      }
        ,
        error => {

        });
  }


  fileChange(event) {

    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.uploadedFile = fileList[0];
    }
    else {
      this.uploadedFile = null;
    }
  }
  //Amartya 29/02/2024
  ChangeSBU(SBU) {
    this.selectedSBUArray = [];
    for (var o in SBU) {
      this.selectedSBUArray.push(SBU[o].itemName);
    }
    
    this.Showselectedcalc();
  }

  Changeplan(plan) {
    this.selectedPlanArray = [];
    for (var o in plan) {
      this.selectedPlanArray.push(plan[o].id);
    }

    this.Showselectedcalc();
  }

  ShowOptiontype(value: number) {
    this.optionSelected == 0;
    this.selectedAppType = 0;
    this.selectedperiodId = 0;
    this.selectedplan = '';
    this.selectedSBU = '';
    /*this.SBUDistinctModel = 0;*/
    this.SelectCalcForOption = 0;
    this.SelectedUploadId = value;
    this.GetDataForCalucation();
  }

  Showselectedoption(value: number) {
    this.optionSelected = value;
    this.GetDataForCalucation();

  }

  GetDataForCalucation() {
    if (this.optionSelected == 1) {
      this.GetMasterFor_HunterUSARoleInSMA(this.selectedperiodId);
    }
    else {

    }

  }
  onChangeOFPeriod(value: number) {

    if (value != 0 || value != undefined) {
      this.selectedperiodId = value;
      /*value = this.UploadOptionTypes;*/

    }
    else {
      this.selectedperiodId = '0';
    }
    this.Showselectedcalc();

    for (var j in this.userInfo.SPMRoles) {
      if (this.userInfo.SPMRoles[j].RoleID.toString().includes('12')) { 
        this.RoleFlag = '1'
        break;
      }
      if (this.userInfo.SPMRoles[j].RoleID.toString().includes('11')) { 
        this.RoleFlag = '2'
        break;
      }
      if (this.userInfo.SPMRoles[j].RoleID.toString().includes('13')) { 
        this.RoleFlag = '2'
      }
      else if (this.userInfo.SPMRoles[j].RoleID.toString().includes('17')) { 
        this.RoleFlag = '3'
      }
    }
  }




  GetSelectedPSNo(items: any) {


    console.log(items);
    this.selectedPSNOForCalc = items;
    /*    this.selectedPSNOForCalc = this.selectedPSNo.join();*/


  }

  CalculateIncentiveActuals() {

/*    this.EmployeeIncentiveCalculations(this.selectedPSNOForCalc, this.selectedPSNOForCalc);*/
    this.ApprovePsno = this.selectedPSNOForCalc.toString();
    var Input = {
      "CalcFor": this.ApprovePsno,
      "PSNo": this.ApprovePsno,
      "PeriodId": this.selectedperiodId,
      "AppType": this.selectedAppType
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.EmployeeIncentiveCalculationsURL();
    this.appService.GetDataFromAPI(url, Input)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });


        }
        this.selectedPSNOForCalc = [];
        this.Showselectedcalc();
      }
        ,
        error => {

        });
   
  }



  Verified(event: any) {
    console.log(event);
  }


  Showselectedapptype(value: number) {
    this.selectedAppType = value;
    this.Showselectedcalc();
  }


  /*@ Amartya 05/01/2024**/
  onChangeOFPlan(value: number) {
   /* this.selectedAppType = value;*/
    this.Showselectedcalc();
    this.SearchFor(value);
  }

  onChangeOFSBU(Value: number) {
    this.Showselectedcalc();
    /*this.SearchFor(Value);*/
  }
    //onChangeOFPlan(value: number) {

    //  this.selectedAppType = value;
    //  this.Showselectedcalc();
    //   this.SearchFor(value);
    //  /*this.SearchInAllTabes(value);*/




    //}


  //Amartya 29/02/2024

  Showselectedcalc() {
    var data = {
      "PeriodId": this.selectedperiodId,
      "AppType": this.userInfo.AppType,
      "PlanId": this.selectedPlanArray.join(),
      "SBU": this.selectedSBUArray.join()
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetPSNOsCalculationsURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          
          this.dtPendingwithCNBOriginal = JSON.parse(JSON.stringify(response.ResponseData.dtPendingwithCNB));

          this.dtCalculated = response.ResponseData.dtcalculated;
          this.dtPendingWithCPOriginal = JSON.parse(JSON.stringify(response.ResponseData.dtPendingwithCP));
          this.dtCalculatedOriginal = JSON.parse(JSON.stringify(response.ResponseData.dtcalculated));
          this.dtPendingwithCNB = response.ResponseData.dtPendingwithCNB;

          this.dtPendingwithCNBprod = response.ResponseData.dtPendingwithCNBprod;
          this.dtPendingwithCP = response.ResponseData.dtPendingwithCP;
          this.dtPendingwithSEprod = response.ResponseData.dtPendingwithSEprod;
          this.dtplanmaster = response.ResponseData.dtPlan;
          this.dtSBUMaster = response.ResponseData.dtSBU;
          //Amartya 29/02/2024
          this.count = 0;
          this.dropdownListSBU = [];
          for (var o in this.dtSBUMaster) {
            this.count = this.count + 1;
            this.dropdownListSBU.push({ "id": this.count, "itemName": this.dtSBUMaster[o].SBU });
          }
          
          this.dropdownListPlan = [];
          for (var o in this.dtplanmaster) {
            this.dropdownListPlan.push({ "id": this.dtplanmaster[o].PlanId, "itemName": this.dtplanmaster[o].PlanName });
          }
         
          
          if (this.selectedAppType != 0 || this.selectedAppType != undefined) {
            this.dtplanmaster = this.dtplanmaster.filter((obj) => { return obj.AppType == this.selectedAppType; });
          }
         
        }
      }
        ,
        error => {

        });

  }

  ToggleGrid(event: MatTabChangeEvent) {
    var time = 1000;
    console.log(event);
  }

  SelectRecords(val, period, actiontype: number) {
    if (actiontype.toString() == '1') {
      for (var o in this.dtCalculated) {
        if (val.toString() == this.dtCalculated[o].PSNo.toString()) {
          if (this.dtCalculated[o].select = !this.dtCalculated[o].select) {
            this.dtCalculated[o].select = 1;
          }
          for (let i = 0; i < this.dtCalculated.length; i++) {
            if (val.toString() == this.dtCalculated[i].PSNo.toString()) {
              if (this.dtCalculated[i].select == 1) {
                this.selectedPSNOForCalc.push(this.dtCalculated[i].PSNo)
              }
            }
          }

          for (let i = 0; i < this.dtCalculated.length; i++) {
            if (val.toString() == this.dtCalculated[i].PSNo.toString()) {
              if (this.dtCalculated[i].select == false) {
                this.selectedPSNOForCalc.pop(this.dtCalculated[i].PSNo)
              }
            }
          }
        }
      }
    }
    else if
      (actiontype.toString() == '2') {
      for (var o in this.dtPendingwithCNB) {
        if (val.toString() == this.dtPendingwithCNB[o].PSNo.toString()) {
          if (this.dtPendingwithCNB[o].select = !this.dtPendingwithCNB[o].select) {
            this.dtPendingwithCNB[o].select = 1;
          }
          for (let i = 0; i < this.dtPendingwithCNB.length; i++) {
            if (val.toString() == this.dtPendingwithCNB[i].PSNo.toString()) {
              if (this.dtPendingwithCNB[i].select == 1) {
                this.selectedPSNOForApprovebyCNB.push(this.dtPendingwithCNB[i].PSNo)
              }
            }
          }

          for (let i = 0; i < this.dtPendingwithCNB.length; i++) {
            if (val.toString() == this.dtPendingwithCNB[i].PSNo.toString()) {
              if (this.dtPendingwithCNB[i].select == false) {
                this.selectedPSNOForApprovebyCNB.pop(this.dtPendingwithCNB[i].PSNo)
              }
            }
          }
        }
      }
    }
    else if
      (actiontype.toString() == '3') {
      for (var o in this.dtPendingwithCP) {
        if (val.toString() == this.dtPendingwithCP[o].PSNo.toString()) {
          if (this.dtPendingwithCP[o].select = !this.dtPendingwithCP[o].select) {
            this.dtPendingwithCP[o].select = 1;
          }
          for (let i = 0; i < this.dtPendingwithCP.length; i++) {
            if (val.toString() == this.dtPendingwithCP[i].PSNo.toString()) {
              if (this.dtPendingwithCP[i].select == 1) {
                this.selectedPSNOForApprovebyCP.push(this.dtPendingwithCP[i].PSNo)
              }
            }
          }

          for (let i = 0; i < this.dtPendingwithCP.length; i++) {
            if (val.toString() == this.dtPendingwithCP[i].PSNo.toString()) {
              if (this.dtPendingwithCP[i].select == false) {
                this.selectedPSNOForApprovebyCP.pop(this.dtPendingwithCP[i].PSNo)
              }
            }
          }
        }
      }
    }
    else if
      (actiontype.toString() == '4') {
      for (var o in this.dtPendingwithCNBprod) {
        if (val.toString() == this.dtPendingwithCNBprod[o].PSNo.toString()) {
          if (this.dtPendingwithCNBprod[o].select = !this.dtPendingwithCNBprod[o].select) {
            this.dtPendingwithCNBprod[o].select = 1;
          }
          for (let i = 0; i < this.dtPendingwithCNBprod.length; i++) {
            if (val.toString() == this.dtPendingwithCNBprod[i].PSNo.toString()) {
              if (this.dtPendingwithCNBprod[i].select == 1) {
                this.selectedPSNOForApprovebyCP.push(this.dtPendingwithCNBprod[i].PSNo)
              }
            }
          }

          for (let i = 0; i < this.dtPendingwithCNBprod.length; i++) {
            if (val.toString() == this.dtPendingwithCNBprod[i].PSNo.toString()) {
              if (this.dtPendingwithCNBprod[i].select == false) {
                this.selectedPSNOForApprovedbyCNBProd.pop(this.dtPendingwithCNBprod[i].PSNo)
              }
            }
          }
        }
      }
    }
    else if
      (actiontype.toString() == '5') {
      for (var o in this.dtPendingwithSEprod) {
        if (val.toString() == this.dtPendingwithSEprod[o].PSNo.toString()) {
          if (this.dtPendingwithSEprod[o].select = !this.dtPendingwithSEprod[o].select) {
            this.dtPendingwithSEprod[o].select = 1;
          }
          for (let i = 0; i < this.dtPendingwithSEprod.length; i++) {
            if (val.toString() == this.dtPendingwithSEprod[i].PSNo.toString()) {
              if (this.dtPendingwithSEprod[i].select == 1) {
                this.selectedPSNOForApprovebyCP.push(this.dtPendingwithSEprod[i].PSNo)
              }
            }
          }

          for (let i = 0; i < this.dtPendingwithSEprod.length; i++) {
            if (val.toString() == this.dtPendingwithSEprod[i].PSNo.toString()) {
              if (this.dtPendingwithSEprod[i].select == false) {
                this.selectedPSNOForApprovedbySEProd.pop(this.dtPendingwithSEprod[i].PSNo)
              }
            }
          }
        }
      }
    }
  }
    


  ApprovebyCNB(actionType: number) {
    if (actionType == 2 || actionType == 3 || actionType == 4) {
      this.ApprovePsno = this.selectedPSNOForApprovebyCP.toString();

      var data = {
        'PSNo': this.ApprovePsno,
        'PeriodId': this.selectedperiodId,
        'ActionType': actionType.toString(),
        "AppType": this.userInfo.AppType
      }
      this.appService.SetLoadingShow(true);
      var url = this.configs.CalculationsApprovalURL();
      this.appService.GetDataFromAPI(url, data)
        .then(response => {
          this.appService.SetLoadingShow(false);
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });


          }
        }
          ,
          error => {

          });
    }
    if (actionType == 1) {

      this.ApprovePsno = this.selectedPSNOForApprovebyCNB.toString();
      this.MoveDataToProd();
/*      this.CalculateDatainProd();*/

    }
    this.selectedPSNOForApprovebyCNB = [];
    this.selectedPSNOForApprovebyCP = [];
      this.Showselectedcalc();


  }


  LoadUploadsForUser() {
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetUploadsForCalculationURL();
    this.appService.GetDataFromAPI(url, "")
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {

          this.filters.dtUploadsMaster = response.ResponseData.dtUploads;
          this.filters.dtCalculatePSNo = response.ResponseData.dtCalculatePSNo;
          this, this.dropdownList = [];
          for (var o in this.filters.dtCalculatePSNo) {
            this.dropdownList.push({ "id": this.filters.dtCalculatePSNo[0].PSNO });
          }
          this.selectedPSNo = JSON.parse(JSON.stringify(this.dropdownList));
        }
      }
        ,
        error => {

        });
  }

  UploadFile() {

    var data = {
      'PeriodId': this.selectedperiodId,
      'AppType': this.userInfo.AppType
    };

    var url = '';

    if (this.SelectedUploadId == 6 || this.SelectedUploadId == 9 || this.SelectedUploadId == 12 || this.SelectedUploadId == 14) {//PID &CGC
      if (this.typeSelected == 1)
        url = this.configs.PIDMappingURL();
      else if (this.typeSelected == 2)
        url = this.configs.AccountMappingURL();
      if (this.userInfo.AppType == 2) {
        url = this.configs.PIDMappingURL();
        if (this.ShowIOptionTypeSection == 9) {
          this.MappingType = 'CGC';
        }
        else if (this.ShowIOptionTypeSection == 12) {
          this.MappingType = 'PID';
        }
      }
      //Past Revenue Upload
      if (this.SelectedUploadId == 14) {
        if (this.typeSelected == 1) {
          url = this.configs.PastYear_PIDMappingURL();
          this.YearType = 'PastYearPID'
        }
        else if (this.typeSelected == 2) {
          url = this.configs.Past_AccountMappingURL();
          this.YearType = 'PastYearCGC'
        }
      }

      if (this.uploadedFile != null) {
        this.appService.SetLoadingShow(true);
        this.appService.FileUpLoadActuals(url, this.uploadedFile, this.selectedperiodId, this.userInfo.AppType, this.MappingType, this.userInfo.PS_No, this.YearType, '')
          .then(response => {
            if (response.ResponseCode == this.configs.RetCodeFailure()) {
              this.appService.SetLoadingShow(false);
              this.Message = response.ResponseData;
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });
            }
            else {
              this.uploadedFile = null;
              this.appService.SetLoadingShow(false);
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });
            }

          });
      }


    }

    if (this.SelectedUploadId == 23) {//New alternate PID-CGC
      url = this.configs.DUPortfoliomappingForActualURL();
      if (this.uploadedFile != null) {
        this.appService.SetLoadingShow(true);
        this.appService.FileUpLoadActuals(url, this.uploadedFile, this.selectedperiodId, this.userInfo.AppType, '', this.userInfo.PS_No, '', '')
          .then(response => {
            if (response.ResponseCode == this.configs.RetCodeFailure()) {
              this.appService.SetLoadingShow(false);
              this.Message = response.ResponseData;
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });
            }
            else {
              this.uploadedFile = null;
              this.appService.SetLoadingShow(false);
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });
            }

          });
      }
    }
  }

  /*Amartya  3/04/2024*/

  SearchFor(value): any {
  if (this.optionSelected == 2 && this.selectedperiodId != '0' && this.selectedAppType != '') {
    var data = JSON.parse(JSON.stringify(this.dtCalculatedOriginal));
    //var data = JSON.parse(JSON.stringify(this.dtPendingwithCNB));
    //var data = JSON.parse(JSON.stringify(this.dtPendingwithCP));
    

    }
    else

      if (this.optionSelected == 1 && this.selectedperiodId != '') {
        var data = JSON.parse(JSON.stringify(this.dtHunterData_Content));

      }
    
   
    var data = JSON.parse(JSON.stringify(this.dtCalculatedOriginal));
    this.dtCalculated = this.SearchInAllTabes(data, value);
    //9/05/2024
    this.selectedPSNOForCalc.forEach((selectedpsno) => {
      for (var o in this.dtCalculated) {
        if (selectedpsno.toString() == this.dtCalculated[o].PSNo.toString()) {
        
          this.dtCalculated[o].select = 1;
        }
      }
    }
    )

    data = JSON.parse(JSON.stringify(this.dtPendingwithCNBOriginal));
    this.dtPendingwithCNB = this.SearchInAllTabes(data, value);
    this.selectedPSNOForApprovebyCNB.forEach((selectedpsno) => {
      for (var o in this.dtPendingwithCNB) {
        if (selectedpsno.toString() == this.dtPendingwithCNB[o].PSNo.toString()) {
         
          this.dtPendingwithCNB[o].select = 1;
        }
      }
    }
    )

    data = JSON.parse(JSON.stringify(this.dtPendingWithCPOriginal));
    this.dtPendingwithCP = this.SearchInAllTabes(data, value);
    this.selectedPSNOForApprovebyCP.forEach((selectedpsno) => {
      for (var o in this.dtPendingwithCP) {
        if (selectedpsno.toString() == this.dtPendingwithCP[o].PSNo.toString()) {
          this.dtPendingwithCP[o].select = 1;
        }
      }
    }
    )
  }

  SearchInAllTabes(data: any, value:string) {

    if (value != '' && value != undefined) {
      if (value.length > 2) {
        const filterValue = value.toLowerCase();
        this.SearchText = value;
        for (var o in data) {
          if (data[o].Name == null)
            data[o].Name = "";
          // if (data[o].manager == null)
          //   data[o].manager = "";
          // if (data[o].reviewer == null)
          //  data[o].reviewer = "";
          if (data[o].PSNo == null)
            data[o].PSNo = "";
          //if (data[o].PlanName == null)
          //  data[o].PlanName = "";
        }
        
        data = data.filter(option => option.Name.toString().toLowerCase().includes(filterValue) || option.PSNo.toString().toLowerCase().includes(filterValue)); /*|| *//*option.PlanName.toString().toLowerCase().includes(filterValue))*/
      }
    }
    return data;
  }

  //#region This function is to search employee by PSNO Amartya 01-01-2024
  //SearchForEmployeeByPSNO(PSNO): any
  //{
  //  var dataCalculated = JSON.parse(JSON.stringify(this.dtCalculated));
  //  var dataPendingWithCNB = JSON.parse(JSON.stringify(this.dtPendingwithCNB));
  //  var dataPendingWithCP = JSON.parse(JSON.stringify(this.dtPendingwithCP));

    
  //  if (PSNO != '' && PSNO != undefined && PSNO.length > 3) {

      
  //    this.isDTCalculated = dataCalculated.filter(employee => employee.psno.toLowerCase().includes(PSNO));
  //    this.isPendingWithCNB = dataPendingWithCNB.filter(employee => employee.psno.toLowerCase().includes(PSNO));
  //    this.isPendingWithCP = dataPendingWithCP.filter(employee => employee.psno.toLowerCase().includes(PSNO));


  //    //if employee is in Calculate stage. Status is Calculation Stage
  //    if (this.isDTCalculated != '') {
  //      this.employeeStatus = "Calculation Stage";
  //    }
  //    else if (this.isPendingWithCNB != '') {
  //      this.employeeStatus = "Pending with CNB";
  //    }
  //    else if (this.isPendingWithCP != '') {
  //      this.employeeStatus = "Pending with CP";
  //    }
  //    else {
  //      this.employeeStatus = 'NA';
  //    }
  //  }
  //}
  //#endregion

  GetMasterFor_HunterUSARoleInSMA(id): void {
    var Input = {
      "PeriodId": this.selectedperiodId,
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetMasterFor_HunterUSARoleInSMAURL();
    this.appService.GetDataFromAPI(url, Input)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dtHunterData_Content = response.ResponseData.dtHunterData_Content;
          this.dtHunterData_Header = response.ResponseData.dtHunterData_Header;
        }
      }
        ,
        error => {

        });
  }
  ClickToAddHunterUSARoleInSMA(PeriodId, PSNO): void {
    var Input = {
      "PeriodId": this.selectedperiodId,
      "PSNO": PSNO
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.AddHunterUSARoleInSMAURL();
    this.appService.GetDataFromAPI(url, Input)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
          this.GetMasterFor_HunterUSARoleInSMA(PeriodId);

        }
      }
        ,
        error => {

        });
  }

  DownLoadExcelReport(): void {
    //call api  >
    var PeriodName = '';
    for (var o in this.masterData.dtPeriodDet) {
      if (this.masterData.dtPeriodDet[o].PeriodID == this.selectedperiodId )
        PeriodName = this.masterData.dtPeriodDet[o].Name;

    }
    if (this.optionSelected == 2 && this.selectedperiodId != '0' && this.selectedAppType != '') { }
    var TargetScreenRoles = this.appService.GetUserRoleForTargetApproval(this.userInfo.SPMRoles);
    if (TargetScreenRoles.CNBRoleId > 0) {
      this.LoggedInUserRoleText = 'CNB';
    }
    else {
      this.LoggedInUserRoleText = 'NONCNB';
    }
    var data = { 
      "Periodvalues": this.selectedperiodId,
      "AppType": this.selectedAppType,
      'DataFor': this.LoggedInUserRoleText,
      'PeriodName': PeriodName
    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetDetailedReportURL(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {
          this.Reportnumber = response.ResponseData.ReportNumber;
          this.DownloadFilePathForDetailedReport = this.DownloadFilePathBaseForDetailedReport + PeriodName + '_version_' + this.Reportnumber + ".xlsx";
          window.open(this.DownloadFilePathForDetailedReport);
          // window.open(this.configs.DetailedReportExcelFileURl());
        }
      });
  }

  DownLoadExcelReportprod(): void {
    //call api  >
    var PeriodName = '';
    for (var o in this.masterData.dtPeriodDet) {
      if (this.masterData.dtPeriodDet[o].PeriodID == this.selectedperiodId)
        PeriodName = this.masterData.dtPeriodDet[o].Name;

    }
    if (this.optionSelected == 2 && this.selectedperiodId != '0' && this.selectedAppType != '') { }
    var data = {
      "Periodvalues": this.selectedperiodId,
      "AppType": this.selectedAppType,
      'DataFor': 'CNB',
      'PeriodName': PeriodName
    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetDetailedReportprodURL(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {
          this.Reportnumber = response.ResponseData.ReportNumber;
          this.DownloadFilePathForDetailedReport = this.DownloadFilePathBaseForDetailedReport + PeriodName + '_version_' + this.Reportnumber + ".xlsx";
          window.open(this.DownloadFilePathForDetailedReport);
          // window.open(this.configs.DetailedReportExcelFileURl());
        }
      });
  }
  MoveDataToProd() {

  /*    this.EmployeeIncentiveCalculations(this.selectedPSNOForCalc, this.selectedPSNOForCalc);*/
    this.ApprovePsno = this.selectedPSNOForApprovebyCNB.toString();
    var Input = {
      "CalcFor": this.ApprovePsno,
      "PSNo": this.ApprovePsno,
      "PeriodId": this.selectedperiodId,
      "AppType": this.selectedAppType
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.MoveDataToProdURL();
    this.appService.GetDataFromAPI(url, Input)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });


        }
        this.selectedPSNOForCalc = [];
        this.Showselectedcalc();
      }
        ,
        error => {

        });

  }

  CalculateDatainProd() {

    /*    this.EmployeeIncentiveCalculations(this.selectedPSNOForCalc, this.selectedPSNOForCalc);*/
    this.ApprovePsno = this.selectedPSNOForApprovebyCNB.toString();
    var Input = {
      "CalcFor": this.ApprovePsno,
      "PSNo": this.ApprovePsno,
      "PeriodId": this.selectedperiodId,
      "AppType": this.selectedAppType
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.CalculateInProdURL()
    this.appService.GetDataFromAPI(url, Input)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });


        }
        this.selectedPSNOForCalc = [];
        this.Showselectedcalc();
      }
        ,
        error => {

        });

  }

  CalculationViewPage(PSNo, selectedperiodId, isAccessvalue, SelectedUploadId, typeSelected, optionSelected, selectedAppType, selectedplan, selectedSBU) {
    this.appService.SetSelectedUploadId(SelectedUploadId);
    this.appService.SeTypeSelected(typeSelected);
    this.appService.SetOptionSelected(optionSelected);
    this.appService.SetSelectedAppType(selectedAppType);
    this.appService.SetSelectedPlan(selectedplan);
    this.appService.SetSelectedSBU(selectedSBU);
    this.appService.SetisAccessValue(isAccessvalue);
    this.appService.SetPsno(PSNo);
    this.appService.SetperiodId(selectedperiodId);
    this.appService.SetApprovalType(this.selectedAppType);
    this.router.navigate(['calculations-details']);
  }
}






