import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogDataExampleDialog } from '../popup.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { MzModalComponent } from 'ngx-materialize';

@Component({
  selector: 'view-actuals',
  templateUrl: './actuals.component.html',
  styleUrls: ['./actuals.component.less']
})
export class ActualsComponent implements OnInit {
  userInfo: any;
  masterData: any;
  MetricsData: any;
  ActualData: any;
  selectedperiodId: any;
  ShowData: boolean = false;
  SalesMetricID: any;
  Plan: any;
  Reportnumber: any;
  DownloadFilePathForDetailedReport: any;
  DownloadFilePathBaseForDetailedReport: any;
  //DownloadFilePath: any;
  selectedAppType: any;
  optionSelected; any;
  //Reportnumber: any;
  TCVReport: any;
  
  constructor(private appService: AppService, private configs: Configs, private _router: Router, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.MetricsData = [];
    this.ActualData = {};
    this.selectedAppType = 0;
    this.optionSelected = 0;
    this.Reportnumber = '';
    this.TCVReport = '';

    //this.DownloadFilePath = this.configs.DashboardAccoutWiseReportURL();
    this.DownloadFilePathBaseForDetailedReport = '../../../UploadTemplates/Report_';
  }

  ngOnInit() {
    this.userInfo = this.appService.GetLoginUserInfo();
    this.masterData = this.appService.GetMasterDetails();
    this.ActualData = this.appService.GetActualData();
    if (this.userInfo != null && this.ActualData != null) {
       
      this.SalesMetricID = this.ActualData['SalesMetricID'];
      this.Plan = this.ActualData['Plan'];

      for (var o in this.ActualData['ActualDataList']) {
        this.ActualData['ActualDataList'][o].SortIndex = (parseInt(o) + 1);
        this.MetricsData.push(this.ActualData['ActualDataList'][o]);
      }
      //this.MetricsData.sort((a, b) => b.SortIndex < a.SortIndex);
      this.GetData();
    }
    else {
      this._router.navigate(['no-access']);
    }
    

  }
  onBackClick(): void {
    this.appService.SetFromHistory('Y');
    this._router.navigate([this.ActualData['Path']]);
  }

  GetData() {

    var ActualDataList = [];

    for (var o in this.ActualData['ActualDataList']) {
      if (this.ActualData['ActualDataList'][o].MetricId == this.SalesMetricID) {
        ActualDataList.push(this.ActualData['ActualDataList'][o]);
        break;
      }
    }


    var data = {
      "PSNo": this.ActualData['PSNo'],
      "PeriodID": this.ActualData['PeriodID'],
      "ActualDataList": ActualDataList,
      "AppType": this.userInfo.AppType,
      "Plan": this.Plan,

    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetActualDataURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          const idx = this.MetricsData.findIndex((d) => d.MetricId === this.SalesMetricID); 
          //this.MetricsData = response.ResponseData;
          for (var o in response.ResponseData) {
            if (response.ResponseData[o].MetricId == this.SalesMetricID) {
              this.MetricsData[idx].Accounts = response.ResponseData[o].Accounts;
            }
           
          }
         
          this.ShowData = true;
        }
      },
        error => {
          // this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
        });
  }
  ShowDivSection(id) {
    if (this.SalesMetricID == id) {
      this.SalesMetricID = '0';
    }
    else {
      this.SalesMetricID = id;
      const idx = this.MetricsData.findIndex((d) => d.MetricId === this.SalesMetricID);
      if (this.MetricsData[idx].MetricId == this.SalesMetricID && this.MetricsData[idx].Accounts.length > 0) {
        this.ShowData = true;
      }
      else {
        this.ShowData = true;
        this.GetData();
      }
    }
  }

  DownLoadExcelReport(salesMetricsID): void {
    //call api  >
    var reportType;
    var data = {
      "PSNo": this.ActualData['PSNo'],
      "PeriodID": this.ActualData['PeriodID'],
      "AppType": this.userInfo.AppType,
      "salesMetricsID": salesMetricsID,
      "Plan": this.Plan,

    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetAccountWiseReportURL();

    this.appService.GetDataFromAPI(url, data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {
          this.Reportnumber = response.ResponseData.ReportNumber;
          if (salesMetricsID == "1" || salesMetricsID == "23") {
            reportType = "_Revenue";
          }
          else if (salesMetricsID == "2") {
            reportType = "_TCV";
          }
          else if (salesMetricsID == "101" || salesMetricsID == "80") {
            reportType = "_ACM";
          }
          else if (salesMetricsID == "26" || salesMetricsID == "4") {
            reportType = "_PAT";
          }
          else if (salesMetricsID == "27") {
            reportType = "_CSAT";
          }
          else if (salesMetricsID == "14") {
            reportType = "_Attrition";
          }
          else if (salesMetricsID == "15") {
            reportType = "_CMF";
          }
          else if (salesMetricsID == "3") {
            reportType = "_ACD";
          }
          this.DownloadFilePathForDetailedReport = this.DownloadFilePathBaseForDetailedReport + reportType + ".xlsx";
          window.open(this.DownloadFilePathForDetailedReport);
        }
      });
  }

}
