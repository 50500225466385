import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
//import { MatTableDataSource } from '@angular/material';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { DialogDataExampleDialog } from '../popup.component';
//import { DataSource } from '@angular/cdk/table';

export interface PeriodicElement {
  Name: string;
  //profilePic:string;
  StatusName: string;
  DeputedBU: string;
  Plans: string;
  period: string;
  Account:string;
}
//const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-reportee-view',
  templateUrl: './reportee-view.component.html',
  styleUrls: ['./reportee-view.component.less']
})


export class ReporteeViewComponent implements OnInit {
  displayedColumns: string[] = [
    'name',
    'sbu',
    'plan',    
    'account',
    'status'
  ];
  //dataSource = new MatTableDataSource(ELEMENT_DATA);
  masterData: any;
  userInfo: any;
  RoleID: any;
  tblStatus: any;
  tblStatusOrginal: any;
  StatusmasterData: any;
  errorMessage: string;
  selectedStatus: any;
  selectedPeriod: any;
  Header: any;
  dropdownListStatus: any = [];
  dropdownSettings: any = {};
  DisPeriodArray: any = [];
  constructor(private appService: AppService, private configs: Configs, private _router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.tblStatus = [];
    this.tblStatusOrginal = [];
    this.StatusmasterData = [];    
     this.route.params.subscribe(params =>
      this.RoleID = params.RoleID);
    if (this.RoleID == 9) {
      this.Header = 'MANAGER ASSESSMENT';
    }
    else if(this.RoleID == 10) {
      this.Header = 'REVIEWER ASSESSMENT';
    }
    else if (this.RoleID == 21) {
      this.Header = 'PRESIDENT SALES ASSESSMENT';
    }
  }

  ngOnInit() {
    const getFullDate = new Date();
    const currentDate = new Date().toISOString().slice(0, 10);
    const getYear = getFullDate.getFullYear();
    const fullYrAprilm = getYear  + '-04-01';
    const fullYrSeptm = getYear + '-09-30';
    var hfyOctm = getYear  + '-10-01';
    var hfyMarchm = getYear + '-03-31';   
   
    this.userInfo = this.appService.GetLoginUserInfo();
    if (this.userInfo != null) {

      var checkAccess = this.appService.CheckAccessForRoute(this._router.url, this.userInfo.UserAccessOptions);
      if (checkAccess) {
      
              this.masterData = this.appService.GetMasterDetails();
              if (this.userInfo.AccessType[0].Showperiodyear == 'isFullyearly') {
                for (var o in this.masterData.dtPeriodDet) {
                  if ((this.masterData.dtPeriodDet[o].PeriodID) % 2 == 0) {
                    this.DisPeriodArray.push(this.masterData.dtPeriodDet[o]);
                  }
                }
                this.masterData.dtPeriodDet = this.DisPeriodArray;
              }
              this.selectedPeriod = this.appService.GetperiodId();
              this.dropdownSettings = {
                singleSelection: false,
                text: "Select",
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: false,
                badgeShowLimit: 1,
                classes: ""
              };
              if (this.RoleID == this.appService.GetRoleId() && this.selectedPeriod != undefined) {
                this.StatusmasterData = this.appService.GetReporteeStatusData();
                this.dropdownListStatus = this.appService.GetdropdownListStatus();
                this.selectedStatus = this.appService.GetStatus();
                this.tblStatusOrginal = this.appService.GetReporteeData();
                this.onChangeStatus(this.selectedPeriod, this.selectedStatus);
                this.onChangeOfFY(this.selectedPeriod);
              }
              else {
                var octdt=null;
                this.appService.SetLoadingShow(true);
                if (currentDate > hfyMarchm) {
                  octdt = getYear + '-10-01';
                  if (currentDate < hfyOctm) {
                    hfyMarchm = getYear + '-03-31';
                  }
                  else {
                    hfyMarchm = getYear + 1 + '-03-31';
                  }
                }
                else if (currentDate <= hfyMarchm) {
                  octdt = getYear - 1 + '-10-01';
                  if (currentDate >= octdt) {
                    hfyMarchm = getYear + '-03-31';
                  }
                  else {
                    hfyMarchm = getYear + 1 + '-03-31';
                  }
                }
                
                if (currentDate >= octdt && currentDate <= hfyMarchm) {
                  this.selectedPeriod = this.masterData.dtPeriodDet[1].PeriodID;
                }
                else if (currentDate >= fullYrAprilm && currentDate <= fullYrSeptm) {
                  this.selectedPeriod = this.masterData.dtPeriodDet[2].PeriodID;
                }
                
                this.onChangeOfFY(this.selectedPeriod);
                var url = this.configs.GetReporteeStatusMasterURL();
                this.appService.GetDataFromAPI(url, '"' + this.RoleID.toString() + '"')
                  .then(response => {
                    if (response.ResponseCode == this.configs.RetCodeFailure()) {
                      this.appService.SetLoadingShow(false);
                      this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                      });
                    }
                    else {
                      this.StatusmasterData = response.ResponseData;
                      this.dropdownListStatus = [];
                      for (var o in this.StatusmasterData) {
                        this.dropdownListStatus.push({ "id": parseInt(o) + 1, "itemName": this.StatusmasterData[o].StatusName });
                      }
                      this.selectedStatus = JSON.parse(JSON.stringify(this.dropdownListStatus));
                      this.appService.SetdropdownListStatus(this.dropdownListStatus);
                    }
                  }
                    ,
                    error => {
                      this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
                    });
              }
      }
      else {
        this._router.navigate(['no-access']);
      }
    }
    else {
      window.location.href = "../Index.html";
    }

    
  }

  onChangeOfFY(value: string): void {
    this.selectedStatus = JSON.parse(JSON.stringify(this.dropdownListStatus));
    this.tblStatusOrginal = [];
    this.tblStatus = [];
    var Input = {
      "PSNo": this.userInfo.PS_No,
      "Period": value,
      "RoleId": this.RoleID,
      "AppType": this.userInfo.AppType
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetReporteeGridDataURL();
    this.appService.GetDataFromAPI(url, Input)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.tblStatusOrginal = response.ResponseData;
          //const ELEMENT_DATA: PeriodicElement[] = response.ResponseData;
          this.tblStatus = response.ResponseData;// new MatTableDataSource(ELEMENT_DATA);
          if (this.tblStatus.length == 0) {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No record found."
            });
            return;
          }
        }
      }
        ,
        error => {
          this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
        });
  }

  onChangeStatus(value: string, status: any): void {

    if (value == undefined) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please select the period to view the reportees."
      });
      return;
    }
    if (status == undefined) {
      this.tblStatus = JSON.parse(JSON.stringify(this.tblStatusOrginal));
      //this.tblStatus.filter = status.trim().toLowerCase();
    } 
    var data = [];

    if (status.length > 0) {
      for (var o in status) {
        if (status[o].itemName != '' || status[o].itemName != undefined || status[o].itemName != "SELECT") {
          for (var i in this.tblStatusOrginal) {
            if (this.tblStatusOrginal[i].StatusName.toLowerCase() == status[o].itemName.toLowerCase() ) {
              data.push(this.tblStatusOrginal[i]);
            }
          }
        }
      }
    }
    else {
      //data = this.tblStatusOrginal;
      data = [];
    }
    this.tblStatus = data;
    //if (this.tblStatus.length == 0) {
    //  this.dialog.open(DialogDataExampleDialog, {
    //    data: "No record found for the selected status."
    //  });
    //  return;
    //}
  }

  onReset(): void {
    this.selectedStatus = [];
    this.selectedStatus = JSON.parse(JSON.stringify(this.dropdownListStatus));
    this.tblStatus = this.tblStatusOrginal;
  }

  onViewAction(value: string, psno: string, selectedStatus: string): void {
   
    if (value != undefined && psno != undefined) {
      this.appService.SetRoleID(this.RoleID);
      this.appService.SetperiodId(value);
      this.appService.SetPsno(psno);
      this.appService.SetStatus(selectedStatus);
      this.appService.StoreReporteeStatusData(this.StatusmasterData);
      this.appService.StoreReporteedata(this.tblStatusOrginal);
      if (this.RoleID == "9")
        this._router.navigate(['manager-view'])
      else if (this.RoleID == "10")
        this._router.navigate(['reviewer-view']);
      else if (this.RoleID == "21")
        this._router.navigate(['executive-view']);
    }

  }

  onChangeRole(role): void {
    this.appService.SetLoadingShow(false);
    this.tblStatus = [];
    this.tblStatusOrginal = [];
    this.StatusmasterData = [];
   
    this.RoleID = role;
    if (this.RoleID == 9) {
      this.Header = 'MANAGER ASSESSMENT';
    }
    else if (this.RoleID == 10) {
      this.Header = 'REVIEWER ASSESSMENT';
    }
    this.ngOnInit();
  }
}
